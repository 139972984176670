@import '../../../../include';

.card {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  column-gap: 40px;
  & img {
    width: 8.25em;
    height: auto;
    margin-bottom: 80px;
  }

  @media #{$phone} {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    place-items: center;
    column-gap: 0;
    margin-bottom: 60px;
    row-gap: 20px;
  }
}

.image {
  max-width: 200px;
  @media #{$phone} {
    text-align: center;
  }
}

.content {
  @media #{$phone} {
    text-align: center;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}

.text {
  font-size: 18px;
  font-weight: 400;
}
