@import '../../include';

.container {
  margin-bottom: 90px;
  max-height: 230px;
  @media #{$phone} {
    margin-bottom: 0;
    max-height: 262px;
    height: 262px;
  }
}

.wrap {
  position: relative;
  box-sizing: border-box;
  display: grid;
  background-color: $colorAccent;
  grid-template-columns: 450px 1fr;
  @media #{$phone} {
    grid-template-columns: 1fr;
  }

  &::before {
    position: absolute;
    top: -1px;
    right: 0;

    content: '';
    display: block;
    width: 300px;
    height: 101%;
    background-color: $colorWhite;
    clip-path: polygon(39px 0, 100% 0, 100% 100%, 0% 100%);

    @media #{$tablet-and-large-desktop} {
      width: calc(300px - ((1920px - 100vw) / 2));
    }
    @media #{$phone} {
      display: none;
    }
  }
}

.imageContainer {
  max-height: 230px;
  max-width: 450px;
  width: 450px;
}

.content {
  display: flex;
  padding: 48px 90px;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  @media #{$tablet-and-large-desktop} {
    width: 61vw;
    padding: 48px 2.5vw;
  }
  @media #{$phone} {
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.title {
  font-size: 42px;
  font-weight: 700;
  color: $colorWhite;
}

.description {
  font-size: 20px;
  font-weight: 400;
  color: $colorWhite;
}

