@import '../../include';

.container {
  @extend %content-container;
  @media #{$phone} {
    padding: 0;
  }
}

.wrap {
  display: grid;
  grid-template-columns: 65% 35%;
  @media #{$phone} {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
}