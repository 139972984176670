@import '../../include';

.container {
  @extend %content-container;
}

.wrap {
  position: relative;
  width: 100%;
}

.content {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 60px;

  @media #{$phone} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 30px;
    row-gap: 30px;
  }
}

.advantage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media #{$phone} {
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    row-gap: 30px;
  }
}

.advantage:first-child .image {
  grid-column: 1 / 2;
  @media #{$phone} {
    grid-row: 1 / 2;
  }
}

.advantage:first-child .advantageContent {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  @media #{$phone} {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}

.advantage:last-child .advantageContent {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  @media #{$phone} {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}

.image {
  width: 41.25em;
  height: auto;
  @media #{$phone} {
    width: 100%;
  }
}

.advantage:last-child .image {
  grid-column: 2 / 3;
  @media #{$phone} {
    grid-column: 1 / 2;
  }
}

.advantageContent {
  display: grid;
  padding: 50px 60px;
  align-items: center;
  justify-content: start;
  @media #{$phone} {
    width: 100%;
    padding: 0;
    row-gap: 30px;
  }
}

.contentItem {
  display: flex;
  gap: 25px;
  align-items: center;

  @media #{$phone} {
    align-items: flex-start;
  }
}

.iconContainer {
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  background-color: $colorBackground;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 2.15em;
    height: auto;
  }
}

.title {
  color: $colorDark;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 10px;
}

.description {
  color: $colorGrey;
  font-size: 18px;
}

.logoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  background-color: $colorWhite;
  width: 235px;
  height: 150px;
  padding: 30px 20px;
  @media #{$scalling-desktop} {
    img {
      width: 12.25rem;
      height: auto;
    }
  }

  @media #{$phone} {
    display: none;
  }

  & > svg {
    width: 17.5em;
    height: auto;
  }
}
