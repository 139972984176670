@import '../../include';

.container {
  @extend %content-container;
  @media #{$phone} {
    padding: 0;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  background-color: $colorDark;

  @media #{$phone} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}

.item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: 30px 50px 39px;
  row-gap: 10px;
  border-right: 2px solid $colorGrey;
  @media #{$phone} {
    border-right: none;
    border-bottom: 2px solid $colorGrey;
  }
}

.item:last-child {
  border: none;
}

.result {
  color: $colorAccent;
  font-size: 60px;
  font-weight: 700;
}

.description {
  color: $colorWhite;
  font-size: 24px;
  font-weight: 700;
}
