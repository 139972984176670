@import '../../include';

.partOurServices {
  @extend %content-container;
  padding-top: 190px;
  @media #{$phone} {
    padding-top: 60px;
  }
}

.servicesList {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 130px;
  @media #{$phone} {
    padding-bottom: 100px;
  }
}

.casesNavigation {
  position: absolute;
  bottom: 60px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  gap: 20px;
  cursor: pointer;
  @media #{$phone} {
    bottom: 30px;
  }
}

.casesPrev {
  display: grid;
  place-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: $colorGrey;
  transition: box-shadow 0.3s ease;
  & svg {
    transform: rotate(180deg);
    width: 0.4186rem;
    height: auto;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}

.casesNext {
  display: grid;
  place-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: $colorAccent;
  transition: box-shadow 0.3s ease;
  & svg {
    width: 0.4186rem;
    height: auto;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}