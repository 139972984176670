@import '../../../include';

.container {
  padding: 40px;
  background: $colorDark;
  color: $colorWhite;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 27px;
}

.formInput {
  margin-bottom: 20px;
}

.formInputsContainer {
  margin-bottom: 27px;
}

.submitButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;
  padding: 10px 30px;

  background: $colorAccent;
  border-radius: 3px;
  box-shadow: 0 0 5px -2px transparent;

  transition: 125ms linear box-shadow;

  &:hover {
    box-shadow: 0 0 8px rgb(250 119 4 / 97%);
  }

  &:active {
    box-shadow: 0 0 8px rgb(250 119 4 / 97%);
  }

  span {
    color: $colorWhite;
    font-size: 20px;
    text-align: center;
  }

  &:disabled {
    background: $colorGreyLight;
    span {
      color: $colorGrey;
    }
  }
}



.formSuccess {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 50px;
  background: $colorGrey;
}

.formSuccessTitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.formSuccessText {
  font-size: 20px;
  text-align: center;
}

