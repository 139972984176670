@import '../../include';

.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 55px 30px 30px;
  background-color: $colorBackground;
  height: 260px;
  @media #{$phone} {
    padding: 50px 30px 30px;
    height: 300px;
  }
}

.imageWrap {
  img {
    width: 26.25em;
    height: auto;

    @media #{$phone} {
      width: 100%;
    }
  }
}

.titleContainer {
  position: absolute;
  top: -35px;
  left: 30px;
  display: flex;
  width: calc(100% - 60px);
  height: 70px;
  @media #{$phone} {
    top: -105px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: f;
  & img {
    width: 2.81em;
    height: auto;
  }
  @media #{$phone} {
    min-width: 100%;
    height: 70px;
    margin-left: auto;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  height: 70px;
  background-color: $colorAccent;
}

.titleWrap {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70px;
  padding: 0 20px;
  background-color: $colorDark;
}

.title {
  color: $colorWhite;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  @media #{$phone} {
    font-size: 20px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 18px;
  margin-bottom: 25px;
}

.textItem {
  position: relative;
  color: $colorGrey;
  font-size: 18px;
  font-weight: 400;

  &::before {
    position: absolute;
    top: 5px;
    left: -14px;
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $colorGrey;
  }
}

.buttonWrap {
  margin: auto auto 0;
}
