@import '../../../include';

.container {
  position: relative;
  padding: 60px;
  overflow: hidden;
  background-color: $colorBackground;
  @media #{$phone} {
    padding: 60px 30px;
  }
}

.advantageContainer {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 10px;

  & svg {
    width: 2.106em;
    height: auto;
  }

  @media #{$phone} {
    justify-content: center;
  }
}

.advantage {
  color: $colorGrey;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.title {
  color: $colorDark;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 50px;
  @media #{$phone} {
    text-align: center;
    margin-bottom: 30px;
  }
}

.reviewNavigation {
  cursor: pointer;
  position: absolute;
  top: 60px;
  right: 60px;
  display: flex;
  gap: 20px;
  @media #{$phone} {
    top: calc(100% - 100px);
    right: 50%;
    transform: translateX(50%);
    z-index: 100;
  }
}

.reviewPrev {
  display: grid;
  place-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: $colorGrey;
  transition: box-shadow 0.3s ease;
  & svg {
    transform: rotate(180deg);
    width: 0.4186rem;
    height: auto;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}

.reviewNext {
  display: grid;
  place-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: $colorAccent;
  transition: box-shadow 0.3s ease;
  & svg {
    width: 0.4186rem;
    height: auto;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}