@import '../../../include';

.container {
  position: relative;

  input,
  textarea {
    width: 100%;

    font-family: var(--font-exo2), sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $colorWhite;
    background: $colorGrey;
    border: none;
    border-radius: 3px;

    transition: border 75ms linear;

    &::placeholder {
      font-weight: 400;
      color: $colorWhite;
    }

    &:focus,
    &:focus-visible,
    &:active {
      border: 1px solid $colorAccent;
      outline: unset;
    }
  }

  input {
    height: 60px;
    padding: 20px 60px 20px 20px;

    &[data-invalid],
    &:invalid {
      color: #f00;
      border: 1px solid #f00;
    }
  }

  textarea {
    resize: none;
    height: 100%;
    padding: 20px;
  }

  .errorIcon {
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    opacity: 0;
    display: grid;
    place-items: center;
    background-color: $colorAccent;
    border-radius: 50%;
    font-weight: 700;
    color: $colorWhite;
    transition: opacity 150ms linear;
  }

  &.hasError {
    border-radius: 5px;
    border: 2px solid #f00;
  }

  &.hasError .errorIcon {
    opacity: 1;
  }

  .errorText {
    position: absolute;
    top: calc(100% + 1px);
    left: 10px;

    color: $colorWhite;

    opacity: 0;

    transition: opacity 150ms linear;
  }

  &.hasError .errorText {
    opacity: 1;
  }
}
