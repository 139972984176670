@import '../../include';

.container {
  @extend %content-container;
  @media #{$phone} {
    padding: 0;
  }
}

.casesNavWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media #{$phone} {
    padding: 0 30px;
    margin-bottom: 0;
  }
}

.casesNav {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;

  @media #{$phone} {
    align-items: center;
  }
}

.casesNavItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 30px;
  border-radius: 20px;
  background-color: $colorGreyLight;
  transition: background-color 0.5s ease;
  box-shadow: none;
  transition: box-shadow 0.3s ease;
  @media #{$phone} {
    height: 30px;
    padding: 0 20px;
  }

  & span {
    color: $colorWhite;
    font-size: 18px;
    font-family: var(--font-exo2), sans-serif;

    @media #{$phone} {
      font-size: 12px;
      font-weight: 700;
    }
  }

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }

  &.active{
    background-color: $colorAccent;
  }
}

.casesList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px;

  @media #{$phone} {
    grid-template-columns: 1fr;
    gap: 0;
    row-gap: 30px;
  }
}
.casesListButtonWrap {
  width: 100%;
  display: grid;
  place-content: center;
}

.casesListButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  padding: 10px 20px;

  color: $colorDark;

  background-color: $colorWhite;
  border: 1px solid $colorAccent;
  border-radius: 3px;
  box-shadow: 0 0 5px -2px transparent;

  transition: 125ms linear box-shadow;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }

  &:active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }

  span {
    font-size: 20px;
    text-align: center;
  }
}

.casesSwiperWrap {
  width: 100%;
  position: relative;
  padding-top: 30px;
  padding-bottom: 60px;
   :global(.swiper-slide-next),
   :global(.swiper-slide-prev) {
      filter: grayscale(1) opacity(0.7) blur(2px);       
  }
  @media #{$phone} {
    padding-bottom: 100px;
  }
}

.casesNavigation {
  position: absolute;
  bottom: 10px;
  right: 60px;
  display: flex;
  gap: 20px;
  cursor: pointer;
  @media #{$phone} {
    right: 50%;
    transform: translateX(50%);
    bottom: 30px;
  }
}

.casesPrev {
  display: grid;
  place-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: $colorGrey;
  transition: box-shadow 0.3s ease;
  & svg {
    transform: rotate(180deg);
    width: 0.4186rem;
    height: auto;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}

.casesNext {
  display: grid;
  place-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: $colorAccent;
  transition: box-shadow 0.3s ease;
  & svg {
    width: 0.4186rem;
    height: auto;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}
