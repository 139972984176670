@import '../../include';

.container {
  padding: 40px 0;
  background: $colorGreyLight;
}

.title {
  text-align: center;
  font-size: 42px;
  margin-bottom: 34px;
}

.iconWrap {
  margin: 0 50px;
  & img {
    height: 3.33em;
    width: auto;
  }
}

.listsWrap {
  position: relative;
  height: 80px;
  padding: 10px 0;
  overflow: hidden;
}


