@import '../../../include';

.partSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.advantageContainer {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 19px;

  & svg {
    width: 2.106em;
    height: auto;
  }
}

.advantage {
  color: $colorGrey;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.title {
  color: $colorDark;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  @media #{$phone} {
    font-size: 40px;
  }
}

.description {
  color: $colorGrey;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.titleWrap {
  margin-bottom: 60px;
  @media #{$phone} {
    margin-bottom: 30px;
  }
}
