@import '../../../include';

.container {
  position: absolute;
  bottom: -100px;
  left: calc((100% - 1320px) / 2);
  display: flex;
  background-color: $colorDark;
  height: 200px;

  @media #{$phone} {
    display: none;
  }
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 30px;
  width: 220px;
  border-right: 2px solid $colorGrey;

  &:last-child {
    border-right: none;
  }

  & img {
    width: 3.75em;
    height: auto;
  }
}

.title {
  font-size: 20px;
  color: $colorWhite;
  text-align: center;
}