@import '../../../include';

.contactBtn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 165px;
  height: 40px;
  padding: 10px 20px;

  color: $colorDark;
  text-decoration: none;

  background: $colorAccent;
  border-radius: 3px;
  box-shadow: 0 0 5px -2px transparent;

  transition: 125ms linear box-shadow;

  &:hover {
    box-shadow: 0 0 8px rgb(250 119 4 / 97%);
  }

  &:active {
    box-shadow: 0 0 8px rgb(250 119 4 / 97%);
  }

  span {
    font-size: 14px;
    text-align: center;
  }

  &.contactBtnBodyColor {
    padding: 10px 30px;
    width: fit-content;
    height: 60px;
    & span {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.1;
    }
  }
  &.contactBtnBodyTransparent {
    padding: 10px 30px;
    min-width: 190px;
    width: fit-content;
    height: 50px;
    background: transparent;
    border: 1px solid $colorAccent;
    & span {
      color: $colorDark;
      font-size: 20px;
      line-height: 1;
    }

    &:hover {
      background: $colorAccent;
      transition: 125ms linear box-shadow;
      & span {
        color: $colorWhite;
      }
    }
  
    &:active {
      background: $colorAccent;
      & span {
        color: $colorWhite;
      }
    }
  }

  &.contactBtnBodyDark {
    padding: 10px 30px;
    width: fit-content;
    height: 60px;
    background: $colorDark;
    color: $colorWhite;
    & span {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.1;
    }
  }
}

.mobileMenuOpened {
  display: none;
}