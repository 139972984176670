@import '../../../include';

.cardContainer {
  position: relative;
}

.imageWrap {
  & img {
    width: 30em;
    height: auto;
  }
  @media #{$phone} {
    width: 100%;
    height: 240px;
    overflow: hidden;
    & img {
      width: 100%;
    }
  }
}

// mixin for content cover in mobile and on hover
@mixin content-cover {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 2px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content {
  display: none;
  @media #{$phone} {
    @include content-cover;
    padding: 63px 30px 30px;
    height: 100%;
  }
}

.card:hover .content {
  @include content-cover;
  padding: 147px 30px 30px;
}

.iconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 3;
  background-color: $colorAccent;

  & svg {
    width: 0.5em;
    height: auto;
  }
}

.title {
  color: $colorWhite;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  margin-top: auto;

  @media #{$phone} {
    font-size: 24px;
  }
}
