@import '../../../include';
.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox,
.label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.label {
  font-size: 0.8rem;
  color: $colorWhite;
}

.checkbox + .label:before {
  content: '';
  background: $colorWhite;
  border-radius: 5px;
  border: 2px solid $colorAccent;
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.checkbox:checked + .label:before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 5px;
  border: solid $colorAccent;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-radius: 0px;
  margin: 0px 15px 5px 5px;
  background: $colorDark;
}
