@import '../../include';

.container {
  position: relative;
  padding-top: 117px;
  background-color: $colorBackground;
  @media #{$phone} {
    padding-top: 70px;
  }
}

.wrap {
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 52% 48%;
  @media #{$phone} {
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 300px;
  padding-bottom: 110px;
  background-color: transparent;

  @media #{$tablet-and-large-desktop} {
    padding-left: calc(300px - ((1920px - 100vw) / 2));
  }

  @media #{$phone} {
    grid-row: 2/3;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    align-items: center;
  }
}

.title {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 30px;
  @media #{$phone} {
    padding-top: 30px;
    font-weight: 700;
    font-size: 42px;
    text-align: center;
    margin-bottom: 45px;
  }
}

.description {
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  color: $colorGrey;
  margin-bottom: 40px;
  @media #{$phone} {
    text-align: center;
    font-weight: 400;
    margin-bottom: 65px;
  }
}

.bannerImageMainDesktopWrap {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  overflow: hidden;
}


.bannerImageMainContainer {
  width: 60.0625em;
  height: 48.125em;
  @media #{$phone} {
    width: 100%;
    height: auto;
  }
}

.bannerImageMainTabletWrap {
  position: relative;
  padding: 0 20px 0 20px;
}

.bannerMobileLogo {
  position: absolute;
  top: 25px;
  z-index: 1;
  width: 14.5625em;
  height: auto;
}
